<template>
	<IndexSlidersTituloContainer
		:titulo="titulo"
		:link-ver-mas="linkVerMas"
	/>
	<div class="flex items-center lg:bg-[#F4F4F4FE] lg:h-[391px]" @click="isPaused=true">
		<img
			v-if="imgSlider"
			class="my-0 hidden lg:inline-block"
			style="width:269px; height: 100%; object-fit: cover;"
			:src="imgSlider"
		>
		<button class="hidden lg:block" @click="previousPage">
			<ClientOnly>				
				<svg
					height="30px"
					width="30px"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 185.343 185.343"
					xml:space="preserve"
					fill="white"
					stroke="#000000"
					transform="rotate(180)"
				>
					<g id="SVGRepo_bgCarrier" stroke-width="0" />

					<g
						id="SVGRepo_tracerCarrier"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>

					<g id="SVGRepo_iconCarrier">
						<g>
							<g>
								<path
									style="fill: black"
									d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175 l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934 c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
								/>
							</g>
						</g>
					</g>
				</svg>
			</ClientOnly>
		</button>
		<div
			ref="slidercontenedor"
			class="carousel hide-scroll grid grid-cols-2 py-4 px-2 rounded-lg lg:flex lg:items-center lg:overflow-y-visible lg:overflow-x-auto lg:p-0 lg:rounded-none gap-2"
			tag="div"
		>
			<template
				v-for="prod,index in productos"
				:key="prod"
			>
				<template v-if="index<4">
					<CardProd :producto="prod" modo-vista="tipo2" />
				</template>
				<template v-else>
					<CardProd class="hidden lg:block" :producto="prod" modo-vista="tipo2" />
				</template>
			</template>
		</div>

		
		<button class="hidden lg:block" @click="nextPage">
			<ClientOnly>
				<svg
					height="30px"
					width="30px"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					stroke="#000000"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 185.343 185.343"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								style="fill: black"
								d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175
							l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934
							c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
							/>
						</g>
					</g>
				</svg>
			</ClientOnly>

		</button>
	
	</div>
</template>
<script lang="ts" setup>
	import type { ProductSlider } from "../../../types/products";
import { useSlider } from '../../../composables/components/useSlider';

	defineProps<{
		titulo: string;
		linkVerMas: string;
		productos: ProductSlider[];
		imgSlider?: string;
	}>();
	
	const { slidercontenedor, nextPage, previousPage, startAutoScroll, isPaused } = useSlider();

	onMounted(() => {
		if(window.screen.width < 768){
			isPaused.value = true;
		}
		startAutoScroll()
	});

</script>
<style scoped>

.carousel > *:nth-child(1) {
    border-bottom:0.1px solid rgb(222, 216, 216);
    border-right:0.1px solid rgb(222, 216, 216);
}
.carousel > *:nth-child(2) {
    border-bottom: 0.1px solid rgb(222, 216, 216);
    border-bottom: 0.1px solid rgb(222, 216, 216);
}
.carousel > *:nth-child(3) {
    border-right: 0.1px solid rgb(222, 216, 216);
}

@media (min-width: 768px) {

	.carousel:nth-child(3) > *:nth-child(n+1) {
		border: none;
	}
}
@media (min-width: 1024px) {
    .carousel {
        overflow-y: visible;
        overflow-x: auto;
    }
}
    </style>
